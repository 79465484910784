import React from 'react';
import makeMainRoutes from './views/routes';

export const makeRoutes = () => {
  const main = makeMainRoutes();

  return (
    main
  );
}

export default makeRoutes;
