import Auth0Lock from 'auth0-lock';
import {EventEmitter} from 'events';
import aramark from 'assets/aramark-vector-logo.svg';

export default class LockAuthService extends EventEmitter {
  constructor() {
    super();

    this.lock = new Auth0Lock(__AUTH0_CLIENT_ID__, __AUTH0_DOMAIN__, {
      allowSignUp: false,
      allowForgotPassword: false,
      closable: true,
      container: 'loginbox',
      avatar: null,
      theme: {
        logo: aramark,
        primaryColor: '#1b5e20'
      },
      auth: {
        redirectUrl: `${window.location.origin}/login`,
        redirect: true,
        autoclose: true,
        responseType: 'token',
        sso: true,
        params: {
          scope: 'profile openid email'
        }
      },
      languageDictionary: {
        title: ''
      },
    });

    this.lock.on('authenticated', (authResult) => {
      this.setToken(authResult.accessToken, authResult.idToken);
      this.setExpiresAt(JSON.stringify(authResult.idTokenPayload.exp * 1000 + new Date().getTime()));

      //load api keys from lambda
      fetch('https://powzzvzi9l.execute-api.us-east-1.amazonaws.com/production/token-retriever', {
        headers: {
          'token': authResult.idToken
        }
      }).then(res => res.json())
      .then(
          (result) => {
            if (result.status && result.status === 'OK') {
              console.log("api key loaded", result)
              this.setApiKeys(result.algoliaKey, result.mapsKey)

              //load user info
              this.lock.getUserInfo(authResult.accessToken, (error, profile) => {
                if (error) {
                  console.error(error);
                  return;
                }
                this.setProfile(profile);

                if (this.history) {
                  this.history.push('/login');
                  this.lock.hide();
                }
              });
            } else {
              console.log("error getting api_keys", result);
            }
            return result;
          },
          (error) => {
            console.log("error getting api_keys", error);
          }
      )
    });

    this.lock.on('authorization_error', (error) => {
      this.lock.show({
        flashMessage: {
          type: 'error',
          text: error.error_description
        }
      });
      this.logout();
    });
  }

  signin(authToken) {
    //load api keys from backend
    fetch(__MAETADATA_API_KEYS_ENDPOINT__, {
      headers: {
        'Authorization': `Bearer ${authToken}`
      }
    }).then(res => res.json())
        .then(
            (result) => {
              if (result.algoliaKey && result.mapsKey) {
                this.setToken(authToken, authToken);
                this.setExpiresAt(JSON.stringify(8640000000000000));
                this.setApiKeys(result.algoliaKey, result.mapsKey);

                //load user info
                this.setProfile({email: result.email});

                this.history.push('/search');
              } else {
                console.log("error getting api_keys", result);
                this.history.push('/login');
              }
              return result;
            },
            (error) => {
              console.log("error getting api_keys", error);
              this.history.push('/login');
            }
        )
  }

  loggedIn() {
    return this.getToken() && this.isAuthenticated() && this.getApiKeys().algoliaKey && this.getApiKeys().gMapsKey;
  }

  isAuthenticated() {
    let expiresAt = JSON.parse(this.getExpiresAt());
    return new Date().getTime() < expiresAt;
  }

  setToken(accessToken, idToken) {
    localStorage.setItem('access_token', accessToken);
    localStorage.setItem('id_token', idToken);
  }

  setApiKeys(algoliaKey, gMapsKey) {
    localStorage.setItem('algolia_key', algoliaKey);
    localStorage.setItem('g_maps_key', gMapsKey);
  }

  setProfile(profile) {
    localStorage.setItem('profile', JSON.stringify(profile));
    this.emit('profile_updated', profile);
  }

  setExpiresAt(expiresAt) {
    localStorage.setItem('expires_at', expiresAt);
  }

  getProfile() {
    const profile = localStorage.getItem('profile');
    return profile ? JSON.parse(localStorage.profile) : {};
  }

  getToken() {
    return localStorage.getItem('id_token');
  }

  getApiKeys() {
    return {
      algoliaKey: localStorage.getItem('algolia_key'),
      gMapsKey: localStorage.getItem('g_maps_key')
    };
  }

  getExpiresAt() {
    return JSON.parse(localStorage.getItem('expires_at'));
  }

  logout() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('profile');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('algolia_key');
    localStorage.removeItem('g_maps_key');
    this.lock.logout({returnTo: `${window.location.origin}/login`, clientID:__AUTH0_CLIENT_ID__});
  }

  show = (connection) => this.lock.show({allowedConnections: [connection]});
}
