export const types = {
  TOGGLE_SAVED_LISTS: 'TOGGLE_SAVED_LISTS',
  TOGGLE_MARKER: 'TOGGLE_MARKER'
}

export const initialState = {
  openSavedLists: false,
  markerVisible: false,
  markerLeft: 0,
  markerValue: 0
}

export const toggleHidden = (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_SAVED_LISTS:
      return {...state, openSavedLists: !state.openSavedLists || action.forceOpen};
    
    case types.TOGGLE_MARKER:
      return {...state, markerVisible: action.visible, markerLeft: action.left, markerValue: action.value};

    default:
      return state;
  }
}

export const toggleSavedLists = function(forceOpen=false) {
  return {
    type: types.TOGGLE_SAVED_LISTS,
    forceOpen
  }
}

export const toggleMarker = function(visible, left, value) {
  return {
    type: types.TOGGLE_MARKER,
    visible,
    left,
    value
  }
}
