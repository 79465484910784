export const types = {
  TOGGLE_LOCAL_FACET: 'TOGGLE_LOCAL_FACET'
}

export const initialState = {
  localSelected: false
}

export const toggleLocalFacet = (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_LOCAL_FACET:
      return {...state, localSelected: action.toggle};

    default:
      return state;
  }
}

export const selected = function(toggle) {
  return {
    type: types.TOGGLE_LOCAL_FACET,
    toggle
  }
}
