export const types = {
  SELECT_LIST: 'SELECT_LIST',
  RETRIEVE_LISTS: 'RETRIEVE_LISTS',
  SELECT_HITS: 'SELECT_HITS'
}

export const initialState = {
  selectedList: {
    user_id: "",
    listName: "",
    list_items: [],
    objectID: ""
  },
  updateTrigger: false,
  selectedHits: undefined
}

export const savedLists = (state = initialState, action) => {
  switch (action.type) {
    case types.SELECT_LIST:
      return {...state, selectedList: action.list};

    case types.RETRIEVE_LISTS:
      return {...state, updateTrigger: !state.updateTrigger};

    case types.SELECT_HITS:
      return Object.assign({}, state, {selectedHits: action.selectedHits});

    default:
      return state;
  }
}

export const selectList = function(list) {
  return {
    type: types.SELECT_LIST,
    list
  }
}

export const retrieveLists = function(trigger) {
  return {
    type: types.RETRIEVE_LISTS,
    trigger
  }
}

export const selectHits = function(selectedHits) {
  return {
    type: types.SELECT_HITS,
    selectedHits
  }
}
