export const types = {
  UPDATE_SEARCH_RADIUS: 'UPDATE_SEARCH_RADIUS',
  MAP_CENTER: 'MAP_CENTER'
}

export const initialState = {
  searchRadius: 0,
  mapCenter: {lat:42.0456384, lng:-87.6807546}
}

export const geoloc = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_SEARCH_RADIUS:
      return {...state, searchRadius: action.radius};

    case types.MAP_CENTER:
      return {...state, mapCenter: action.mapCenter};

    default:
      return state;
  }
}

export const updateSearchRadius = function(radius) {
  return {
    type: types.UPDATE_SEARCH_RADIUS,
    radius
  }
}

export const moveMapCenter = function(mapCenter) {
  return {
    type: types.MAP_CENTER,
    mapCenter
  }
}
