import React from 'react';
import {render} from 'react-dom';
import {Provider} from 'react-redux';
import App from './containers/App/App.js';
import makeRoutes from './routes';
import configureStore from './reducers/configureStore';
import 'normalize.css';
import 'styles/app.scss';

const routes = makeRoutes();
const store = configureStore();

render(
  <Provider store={store}>
    <App routes={routes} />
  </Provider>,
  document.querySelector('#root')
);
