export const types = {
  TOGGLE_MAP: 'TOGGLE_MAP'
}

export const initialState = {
  openMap: false
}

export const toggleProdMap = (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_MAP:
      return {...state, openMap: !state.openMap};

    default:
      return state;
  }
}

export const toggleMap = function() {
  return {
    type: types.TOGGLE_MAP
  }
}
