import {createStore} from 'redux';
import rootReducer from './rootReducer';

export default function configureStore(state) {
  const store = createStore(
    rootReducer
  );

  return store;
}
