import React, {Suspense, lazy} from 'react';
import {Route, Redirect, Switch} from 'react-router-dom';
import LockAuthService from '../utils/LockAuthService';
import {InstantSearch} from "react-instantsearch/dom";

const Login  = lazy(() => import('./Login/LockLogin'));
const UserLogin = lazy(() => import('./Login/UserLogin'));
const InstantSearchRoot = lazy(() => import('./Search/InstantSearchRoot'));
const Maps = lazy(() => import('./Maps/Maps'));
const Lists = lazy(() => import('./Lists/Lists'));
const auth = new LockAuthService();

export const makeMainRoutes = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/login"/>} />

        <Route path="/login"
          render={({history: {location}, history}) => {
            auth.history = history;
            if (!auth.loggedIn())
              return <Login auth={auth} location={location} history={history}/>;
            else
              return <Redirect to="/search"/>;
          }}
        />

        <Route path="/fff1616"
          render={({history: {location}, history}) => {
            auth.history = history;
            if (!auth.loggedIn())
              return <UserLogin auth={auth} location={location} history={history}/>;
            else
              return <Redirect to="/search"/>;
          }}
        />

        <Route path="/sso"
             render={({history: {location}, history}) => {
                 auth.history = history;

                 const authToken = (new URLSearchParams(location.search)).get("authToken");
                 auth.signin(authToken);
             }}
        />

        <Route exact path="/search"
          render={
            ({history: {location}, history}) => {
              auth.history = history;
              if (auth.loggedIn())
                  return <InstantSearch
                      appId={__APP_ID__}
                      apiKey={auth.getApiKeys().algoliaKey}
                      indexName={__INDEX_NAME__}>
                      <InstantSearchRoot auth={auth} location={location} history={history}/>
                  </InstantSearch>
              else
                return <Redirect to="/login"/>;
            }
          }
        />

        <Route exact path="/maps"
          render={
            ({history: {location}, history}) => {
              auth.history = history;
              if (auth.loggedIn())
                  return <InstantSearch
                      appId={__APP_ID__}
                      apiKey={auth.getApiKeys().algoliaKey}
                      indexName={__INDEX_NAME__}>
                      <Maps auth={auth} location={location} history={history}/>
                  </InstantSearch>;
              else
                return <Redirect to="/login"/>;
            }
          }
        />

        <Route exact path="/lists"
          render={
            ({history: {location}, history}) => {
              auth.history = history;
              if (auth.loggedIn())
                  return <InstantSearch
                      appId={__APP_ID__}
                      apiKey={auth.getApiKeys().algoliaKey}
                      indexName={__INDEX_NAME__}>
                      <Lists auth={auth} location={location} history={history}/>
                  </InstantSearch>;
              else
                return <Redirect to="/login"/>;
            }
          }
        />

      </Switch>
    </Suspense>
  )
}

export default makeMainRoutes;
