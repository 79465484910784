export const types = {
  DISPLAY_MESSAGE: 'DISPLAY_MESSAGE',
  CHANGE_CURSOR: 'CHANGE_CURSOR'
}

export const initialState = {
  message: "",
  cursor: "default"
}

export const feedback = (state = initialState, action) => {
  switch (action.type) {
    case types.DISPLAY_MESSAGE:
      return {...state, message: action.message};

    case types.CHANGE_CURSOR:
      return {...state, cursor: action.cursor}

    default:
      return state;
  }
}

export const displayMessage = function(message) {
  return {
    type: types.DISPLAY_MESSAGE,
    message
  }
}

export const changeCursor = function(cursor) {
  return {
    type: types.CHANGE_CURSOR,
    cursor
  }
}
