import React from 'react';
import {BrowserRouter} from 'react-router-dom';

class App extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div style={{height: '100%'}}>
                <BrowserRouter>
                    {this.props.routes}
                </BrowserRouter>
            </div>
        )
    }
}

export default App;
