export const types = {
  CLEAR_FILTER_SEARCH: 'CLEAR_FILTER_SEARCH'
}

export const initialState = {
  trigger: false
}

export const clearFilterSearch = (state = initialState, action) => {
  switch (action.type) {
    case types.CLEAR_FILTER_SEARCH:
      return {...state, trigger: !state.trigger};

    default:
      return state;
  }
}

export const clear = function() {
  return {
    type: types.CLEAR_FILTER_SEARCH
  }
}
