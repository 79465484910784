import {combineReducers} from 'redux';
import {geoloc} from './Geolocation';
import {savedLists, retrieveLists} from './Savedlists';
import {feedback} from './Feedback';
import {toggleProdMap} from './ToggleProdMap';
import {clearFilterSearch} from './ClearFilterSearch';
import {toggleHidden} from './ToggleHidden';
import {toggleLocalFacet} from './ToggleLocalFacet';

//Main Store creation, will import the other reducers and combine them below
//Think I'm going to use something similar to the "Ducks" strategy from here:
//https://hackernoon.com/my-journey-toward-a-maintainable-project-structure-for-react-redux-b05dfd999b5
//I think I prefer keeping the actions and their functionality in the same file vs separate files/dirs
const rootReducer = combineReducers({
  geoloc: geoloc,
  savedLists: savedLists,
  retrieveLists: retrieveLists,
  feedback: feedback,
  toggleProdMap: toggleProdMap,
  clearFilterSearch: clearFilterSearch,
  toggleHidden: toggleHidden,
  toggleLocal: toggleLocalFacet
});

export default rootReducer;
